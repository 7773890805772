setup();

/**
 * Main setup function. Does all non-urgent checks and setups, such as carousels, tooltips, and filter boxes.
 */
function setup() {
	// Filter boxes open up
	let filterBoxes = document.querySelectorAll('.filter-box.closeable');
	if (filterBoxes.length > 0) {
		filterBoxes.forEach(filterBox => {
			let button = filterBox.querySelector('.filter-box-button');
			button.addEventListener('click', () => {
				if (filterBox.classList.contains('open')) {
					filterBox.classList.remove('open');
				} else {
					filterBox.classList.add('open');
				}
			});
		});
	}

	// Current Offers Carousel
	if (document.querySelector('#current-offers-carousel') != null) {
		let currentOffersCarousel = new KeenSlider('#current-offers-carousel', {
			slides: '.current-offers-carousel-item',
			resetSlide: true,
			loop: true
		});
	}

	// Trip Carousel
	if (document.querySelector('#trip-carousel') != null) {
		let tripCarousel = new KeenSlider('#trip-carousel', {
			slides: '.trip-carousel-item',
			resetSlide: true,
			loop: true,
			breakpoints: {
				'(min-width: 1400px)': {
					slidesPerView: 4
				},
				'(max-width: 1399px)': {
					slidesPerView: 3
				},
				'(max-width: 991px)': {
					slidesPerView: 2
				},
				'(max-width: 767px)': {
					slidesPerView: 1
				}
			}
		});

		let leftControl = document.querySelector(
			'#trip-carousel-container .ctrl-left'
		);
		let rightControl = document.querySelector(
			'#trip-carousel-container .ctrl-right'
		);

		leftControl.addEventListener('click', () => {
			tripCarousel.prev();
		});

		rightControl.addEventListener('click', () => {
			tripCarousel.next();
		});
	}

	if (document.querySelector('#review-carousel') != null) {
		let reviewCarousel = new KeenSlider('#review-carousel', {
			slides: '.review-carousel-item',
			resetSlide: true,
			loop: true,
			slidesPerView: 1
		});

		let leftControl = document.querySelector(
			'#review-carousel-container .ctrl-left'
		);
		let rightControl = document.querySelector(
			'#review-carousel-container .ctrl-right'
		);

		leftControl.addEventListener('click', () => {
			reviewCarousel.prev();
		});

		rightControl.addEventListener('click', () => {
			reviewCarousel.next();
		});
	}

	// Lightbox
	let lightbox = GLightbox();

	// Tooltips
	document.querySelectorAll('.tooltip-button').forEach(button => {
		let tooltip = document.querySelector(
			button.getAttribute('data-tooltip')
		);

		if (tooltip != null) {
			let position = tooltip.getAttribute('data-tooltip-position');

			if (position == null) {
				position = 'top';
			}

			let pixelOffset;

			let svgPoints;
			switch (position) {
				case 'top':
					svgPoints = '0,0 1,2 2,0';
					pixelOffset = [0, 10];
					break;
				case 'bottom':
					svgPoints = '0,2 2,2 1,0';
					pixelOffset = [0, 15];
					break;
				case 'left':
					svgPoints = '0,0 2,1 0,2';
					pixelOffset = [0, 15];
					break;
				case 'right':
					svgPoints = '2,0 2,2 0,1';
					pixelOffset = [0, 15];
					break;
			}

			let svgHTML =
				'<svg viewbox="0 0 2 2"><polygon points="' +
				svgPoints +
				'"></polygon></svg>';

			let arrow = document.createElement('div');
			arrow.classList.add('tooltip-arrow');
			arrow.innerHTML = svgHTML;
			tooltip.appendChild(arrow);

			Popper.createPopper(button, tooltip, {
				placement: position,
				modifiers: [
					{
						name: 'arrow',
						options: {
							element: tooltip.querySelector('.tooltip-arrow'),
							padding: 0
						}
					},
					{
						name: 'offset',
						options: {
							offset: pixelOffset
						}
					}
				]
			});

			button.addEventListener('mouseenter', () => {
				openTooltip(tooltip);
			});

			tooltip.addEventListener('mouseleave', () => {
				closeTooltip(tooltip);
			});
		}
	});
}

/**
 *
 * @param {HTMLElement} tooltip
 */
function openTooltip(tooltip) {
	tooltip.classList.add('open');
}

/**
 *
 * @param {HTMLElement} tooltip
 */
function closeTooltip(tooltip) {
	tooltip.classList.remove('open');
}

/**
 * Toggle Filters menu on mobile
 */
function toggleFilters() {
	document.querySelector('#offering-filters').classList.toggle('open');
}
